import axios from "axios";
import SpinnerMedium from "components/Spinner/Medium";
import { formatDollar } from "components/Table/Simple/presets";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const BrandPage = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [productDatas, setProductDatas] = useState(null);
    const [shopInfo, setShopInfo] = useState(null);

    useEffect(() => {
        fetchData();
    }, [slug])

    const fetchData = async () => {
        try {
            const shopRet = await axios.get(`https://rest.39f.jp/shops`);
            console.log("[fetchData] shopRet", shopRet);
            const found = shopRet.data.find((shop) => shop.slug === slug);
            setShopInfo(found);
            const ret = await axios.get(`https://rest.39f.jp/shops/${slug}/products`);
            console.log("[fetchData] ret", ret);
            setProductDatas(ret.data);
        } catch (e) {
            console.error(e);
        }
    }

    if (_.isNil(productDatas)) {
        return <SpinnerMedium />
    }

    return (
        <div>
            <div className="bg-white">
                <div className="mx-auto max-w-2xl px-4 py-6 sm:px-6 lg:max-w-7xl lg:px-8">
                    <div className="md:flex md:items-center md:justify-between">
                        <h2 className="text-2xl font-bold tracking-tight text-gray-900">{shopInfo.name}</h2>
                    </div>
                    <div>
                        <div className="md:flex md:items-center md:justify-between mt-5">
                            <h2 className="text-xl font-bold tracking-tight text-gray-900">Handbags</h2>
                        </div>
                        <div className="mt-2 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 lg:gap-x-8">
                            {productDatas.filter((product) => product.categoryId === 3).map((product) => (
                                <div key={product.id} className="group relative">
                                    <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80">
                                        <img
                                            src={product.mainImageUrl}
                                            className="h-full w-full object-cover object-center"
                                        />
                                    </div>
                                    <h3 className="mt-4 text-sm text-gray-700">
                                        <a className="cursor-pointer"
                                            onClick={() => { navigate(`/product/${product.id}`) }}
                                        >
                                            <span className="absolute inset-0" />
                                            {product.name}
                                        </a>
                                    </h3>
                                    <p className="mt-1 text-sm text-gray-500">{product.color}</p>
                                    <p className="mt-1 text-sm font-medium text-gray-900">{formatDollar(product.price)}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <div className="md:flex md:items-center md:justify-between mt-5">
                            <h2 className="text-xl font-bold tracking-tight text-gray-900">Ready To Wear</h2>
                        </div>
                        <div className="mt-2 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 lg:gap-x-8">
                            {productDatas.filter((product) => product.categoryId === 2).map((product) => (
                                <div key={product.id} className="group relative">
                                    <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80">
                                        <img
                                            src={product.mainImageUrl}
                                            className="h-full w-full object-cover object-center"
                                        />
                                    </div>
                                    <h3 className="mt-4 text-sm text-gray-700">
                                        <a className="cursor-pointer"
                                            onClick={() => { navigate(`/product/${product.id}`) }}
                                        >
                                            <span className="absolute inset-0" />
                                            {product.name}
                                        </a>
                                    </h3>
                                    <p className="mt-1 text-sm text-gray-500">{product.color}</p>
                                    <p className="mt-1 text-sm font-medium text-gray-900">{formatDollar(product.price)}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="mt-8 text-sm md:hidden">
                        <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                            Shop the collection
                            <span aria-hidden="true"> &rarr;</span>
                        </a>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default BrandPage;