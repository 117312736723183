import React from 'react'
import { Fragment, useState } from 'react'
import {
    Dialog,
    DialogPanel,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
} from '@headlessui/react'
import { Bars3Icon, MagnifyingGlassIcon, ShoppingCartIcon, UserIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'
import logo from 'assets/logo/logo-no-background.png';

const currencies = ['CAD', 'USD', 'AUD', 'EUR', 'GBP']
const navigation = {
    categories: [
        {
            name: 'Shop',
            brands: [
                { name: 'Hermès', to: '/brand/hermes' },
                { name: 'Chanel', to: '/brand/chanel' },
                { name: 'Louis Vuitton', to: '/brand/lv' },
                { name: 'Dior', to: '/brand/dior' },
                // { name: 'Prada', to: '/brand/prada' },
                // { name: 'Gucci', to: '/brand/gucci' },
                // { name: 'Fendi', to: '/brand/fendi' },
            ],
            categories: [
                { name: 'Ready To Wear', to: '/category/ready-to-wear' },
                { name: 'Handbags', to: '/category/handbags' },
                { name: 'Shoes', to: '/category/shoes' },
                { name: 'Accessories', to: '/category/accessories' },
                { name: 'Small Leather Goods', to: '/category/small-leather-goods' },
                { name: 'Travel', to: '/category/travel' },
            ],
        },
        // {
        //     name: 'Men',
        //     categories: [
        //         { name: 'Artwork Tees' },
        //         { name: 'Pants' },
        //         { name: 'Accessories' },
        //         { name: 'Boxers' },
        //         { name: 'Basic Tees' },
        //     ],
        //     brands: [
        //         { name: 'Significant Other' },
        //         { name: 'My Way' },
        //         { name: 'Counterfeit' },
        //         { name: 'Re-Arranged' },
        //         { name: 'Full Nelson' },
        //     ],
        // },
    ],
    pages: [
        { name: 'Preview', to: '/preview' },
        // { name: 'Categories', to: '/category/lists' },
        // { name: 'Products', to: '/product/lists' },
        // { name: 'Overviews', to: '/product/overviews' },
        // { name: 'Features', to: '/product/features' },
        // { name: 'Shopping Cart', to: '/order/shopping-cart' },
        // { name: 'Checkout', to: '/order/checkout' },
        // { name: 'History', to: '/order/history' },
        // { name: 'Summaries', to: '/order/summaries' },
    ],
}

export const PublicTopNav = () => {
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()

    return (
        <div className="bg-white">
            {/* Mobile menu */}
            <Dialog open={open} onClose={setOpen} className="relative z-40 lg:hidden">
                {/* <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
                /> */}

                <div className="fixed inset-0 z-40 flex">
                    <DialogPanel
                        // transition
                        className="relative flex w-full max-w-xs transform flex-col overflow-y-auto bg-white pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:-translate-x-full"
                    >
                        <div className="flex px-4 pb-2 pt-5">
                            <button
                                type="button"
                                onClick={() => setOpen(false)}
                                className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                            </button>
                        </div>

                        <div className="space-y-6 border-b border-gray-200 px-4 py-6">
                            {navigation.pages.map((page) => (
                                <div key={page.name} className="flow-root">
                                    <a className="-m-2 block p-2 font-medium text-gray-900"
                                        onClick={() => {
                                            console.log("[clicked]", page.to);
                                            navigate(page.to)
                                            setOpen(false)
                                        }}
                                    >
                                        {page.name}
                                    </a>
                                </div>
                            ))}
                        </div>

                        {/* Links */}
                        <TabGroup className="mt-2">
                            {/* <div className="border-b border-gray-200">
                                <TabList className="-mb-px flex space-x-8 px-4">
                                    {navigation.categories.map((category) => (
                                        <Tab
                                            key={category.name}
                                            className="flex-1 whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-base font-medium text-gray-900 data-[selected]:border-indigo-600 data-[selected]:text-indigo-600"
                                        >
                                            {category.name}
                                        </Tab>
                                    ))}
                                </TabList>
                            </div> */}
                            <TabPanels as={Fragment}>
                                {navigation.categories.map((category, categoryIdx) => (
                                    <TabPanel key={category.name} className="space-y-12 px-4 py-4">
                                        <div className="grid grid-cols-1 items-start gap-x-6 gap-y-10">
                                            <div className="grid grid-cols-1 gap-x-6 gap-y-10">
                                                <div className="grid grid-cols-1 gap-x-6 gap-y-10">
                                                    <div>
                                                        <p id="mobile-brand-heading" className="font-medium text-gray-900">
                                                            Brands
                                                        </p>
                                                        <ul role="list" aria-labelledby="mobile-brand-heading" className="mt-6 space-y-6">
                                                            {category.brands.map((item) => (
                                                                <li key={item.name} className="flex">
                                                                    <a className="text-gray-500 cursor-pointer"
                                                                        onClick={() => {
                                                                            console.log("[clicked]", item.to);
                                                                            navigate(item.to);
                                                                            setOpen(false)
                                                                        }}
                                                                    >
                                                                        {item.name}
                                                                    </a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p id="mobile-categories-heading" className="font-medium text-gray-900">
                                                        Categories
                                                    </p>
                                                    <ul role="list" aria-labelledby="mobile-categories-heading" className="mt-6 space-y-6">
                                                        {category.categories.map((item) => (
                                                            <li key={item.name} className="flex">
                                                                <a className="text-gray-500 cursor-pointer"
                                                                    onClick={() => {
                                                                        console.log("[clicked]", item.to);
                                                                        navigate(item.to);
                                                                        setOpen(false)
                                                                    }}
                                                                >
                                                                    {item.name}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                ))}
                            </TabPanels>
                        </TabGroup>



                        <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                            <div className="flow-root">
                                <a href="#" className="-m-2 block p-2 font-medium text-gray-900">
                                    Create an account
                                </a>
                            </div>
                            <div className="flow-root">
                                <a href="#" className="-m-2 block p-2 font-medium text-gray-900">
                                    Sign in
                                </a>
                            </div>
                        </div>

                        <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                            {/* Currency selector */}
                            <form>
                                <div className="inline-block">
                                    <label htmlFor="mobile-currency" className="sr-only">
                                        Currency
                                    </label>
                                    <div className="group relative -ml-2 rounded-md border-transparent focus-within:ring-2 focus-within:ring-white">
                                        <select
                                            id="mobile-currency"
                                            name="currency"
                                            className="flex items-center rounded-md border-transparent bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-gray-700 focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-800"
                                        >
                                            {currencies.map((currency) => (
                                                <option key={currency}>{currency}</option>
                                            ))}
                                        </select>
                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                                            <ChevronDownIcon aria-hidden="true" className="h-5 w-5 text-gray-500" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </DialogPanel>
                </div>
            </Dialog>

            <header className="relative">
                <nav aria-label="Top">
                    {/* Secondary navigation */}
                    <div className="bg-white">
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <div className="border-b border-gray-200">
                                <div className="flex h-16 items-center justify-between">
                                    {/* Logo (lg+) */}
                                    <div className="hidden lg:flex lg:items-center">
                                        <a
                                            onClick={() => navigate('/main/home')}
                                        >
                                            <span className="sr-only">Your Company</span>
                                            <img
                                                alt=""
                                                src={logo}
                                                className="h-8 w-auto"
                                            />
                                        </a>
                                    </div>

                                    <div className="hidden h-full lg:flex">
                                        {/* Mega menus */}
                                        <PopoverGroup className="ml-8">
                                            <div className="flex h-full justify-center space-x-8">
                                                {navigation.pages.map((page) => (
                                                    <div
                                                        key={page.name}
                                                        className="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800 cursor-pointer"
                                                        onClick={() => {
                                                            console.log("[clicked]", page.to);
                                                            navigate(page.to)
                                                        }}
                                                    >
                                                        {page.name}
                                                    </div>
                                                ))}
                                                {navigation.categories.map((category, categoryIdx) => (
                                                    <Popover key={category.name} className="flex">
                                                        <div className="relative flex">
                                                            <PopoverButton className="relative z-10 -mb-px flex items-center border-b-2 border-transparent pt-px text-sm font-medium text-gray-700 transition-colors duration-200 ease-out hover:text-gray-800 data-[open]:border-indigo-600 data-[open]:text-indigo-600 focus:outline-none">
                                                                {category.name}
                                                            </PopoverButton>
                                                        </div>

                                                        <PopoverPanel
                                                            //   transition
                                                            className="absolute inset-x-0 top-full text-gray-500 transition data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in sm:text-sm z-[100]"
                                                        >
                                                            {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                                                            {({ close }) => (
                                                                <div>
                                                                    <div aria-hidden="true" className="absolute inset-0 top-1/2 bg-white shadow" />
                                                                    <div className="relative bg-white">
                                                                        <div className="mx-auto max-w-7xl px-8">
                                                                            <div className="grid grid-cols-2 items-start gap-x-8 gap-y-10 pb-12 pt-10">
                                                                                <div className="grid grid-cols-2 gap-x-8 gap-y-10">
                                                                                    <div>
                                                                                        <p id="desktop-brand-heading" className="font-medium text-gray-900">
                                                                                            Brands
                                                                                        </p>
                                                                                        <ul
                                                                                            role="list"
                                                                                            aria-labelledby="desktop-brand-heading"
                                                                                            className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                                                                        >
                                                                                            {category.brands.map((item) => (
                                                                                                <li key={item.name} className="flex">
                                                                                                    <a className="hover:text-gray-800 cursor-pointer"
                                                                                                        onClick={() => {
                                                                                                            console.log("[clicked]", item.to);
                                                                                                            navigate(item.to)
                                                                                                            close()
                                                                                                        }}
                                                                                                    >
                                                                                                        {item.name}
                                                                                                    </a>
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div>
                                                                                        <p id="desktop-categories-heading" className="font-medium text-gray-900">
                                                                                            Categories
                                                                                        </p>
                                                                                        <ul
                                                                                            role="list"
                                                                                            aria-labelledby="desktop-categories-heading"
                                                                                            className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                                                                        >
                                                                                            {category.categories.map((item) => (
                                                                                                <li key={item.name} className="flex">
                                                                                                    <a className="hover:text-gray-800 cursor-pointer" onClick={() => {
                                                                                                        console.log("[clicked]", item.to);
                                                                                                        navigate(item.to)
                                                                                                        close()
                                                                                                    }}>
                                                                                                        {item.name}
                                                                                                    </a>
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="grid grid-cols-2 gap-x-8 gap-y-10">

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </PopoverPanel>
                                                    </Popover>
                                                ))}
                                            </div>
                                        </PopoverGroup>
                                    </div>

                                    {/* Mobile menu and search (lg-) */}
                                    <div className="flex flex-1 items-center lg:hidden">
                                        <button
                                            type="button"
                                            onClick={() => setOpen(true)}
                                            className="-ml-2 rounded-md bg-white p-2 text-gray-400"
                                        >
                                            <span className="sr-only">Open menu</span>
                                            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                                        </button>

                                        {/* Search */}
                                        <a className="ml-2 p-2 text-gray-400 hover:text-gray-500">
                                            <span className="sr-only">Search</span>
                                            <MagnifyingGlassIcon aria-hidden="true" className="h-6 w-6" />
                                        </a>
                                    </div>

                                    {/* Logo (lg-) */}
                                    <a className="lg:hidden">
                                        <span className="sr-only">Your Company</span>
                                        <img
                                            alt=""
                                            src={logo}
                                            className="h-8 w-auto"
                                        />
                                    </a>

                                    <div className="flex flex-1 items-center justify-end">
                                        <div className="flex items-center lg:ml-8">
                                            <div className="flex space-x-8">
                                                <div className="hidden lg:flex">
                                                    <a className="-m-2 p-2 text-gray-400 hover:text-gray-500">
                                                        <span className="sr-only">Search</span>
                                                        <MagnifyingGlassIcon aria-hidden="true" className="h-6 w-6" />
                                                    </a>
                                                </div>

                                                <div className="flex">
                                                    <a className="-m-2 p-2 text-gray-400 hover:text-gray-500">
                                                        <span className="sr-only">Account</span>
                                                        <UserIcon aria-hidden="true" className="h-6 w-6" />
                                                    </a>
                                                </div>
                                            </div>

                                            <span aria-hidden="true" className="mx-4 h-6 w-px bg-gray-200 lg:mx-6" />

                                            <div className="flow-root">
                                                <a className="group -m-2 flex items-center p-2">
                                                    <ShoppingCartIcon
                                                        aria-hidden="true"
                                                        className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                                    />
                                                    <span className="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800">0</span>
                                                    <span className="sr-only">items in cart, view bag</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </div >
    )
}

export default PublicTopNav;
