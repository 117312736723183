import { Routes, Route, Navigate } from "react-router-dom";
import React from "react";
import MainRoute from "containers/SiteRoute/Main";
import CategoryRoute from "containers/SiteRoute/Category";
import ProductRoute from "containers/SiteRoute/Product";
import OrderRoute from "containers/SiteRoute/Order";
import BrandPage from "pages/Brand";
import CategoryPage from "pages/Category";
import PreviewPage from "pages/Preview";

export default () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to={'/main/home/'} />}></Route>
            <Route path="/main/*" element={<MainRoute />}></Route>
            <Route path="/product/*" element={<ProductRoute />}></Route>
            <Route path="/order/*" element={<OrderRoute />}></Route>
            <Route path="/preview" element={<PreviewPage />}></Route>
            <Route path="/brand/" element={<BrandPage />}>
                <Route path=":slug" element={<BrandPage />}></Route>
            </Route>
            <Route path="/category/" element={<CategoryPage />}>
                <Route path=":slug" element={<CategoryPage />}></Route>
            </Route>
        </Routes >
    )
}