import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import PublicSideNav from 'containers/SideNav/public';
import PublicSiteRoute from 'containers/SiteRoute/public';
import PublicTopNav from 'containers/TopNav/public';
import "./App.scss";


function App() {
  return (
    <div className="">
      <PublicTopNav></PublicTopNav>
      {/* <PublicSideNav></PublicSideNav> */}
      <div className="mx-auto max-w-7xl">
        <PublicSiteRoute></PublicSiteRoute>
      </div>
    </div>
  );
}

export default App;